import { TFunction } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// Ui-kit
import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { removeMemberIsLoadingSelector } from 'state/family-profile/family-profile.selectors';

// Utils
import { mapFamilyAccountErrors } from 'util/family-account';
import { getPhoneNumber } from 'util/globalVariables';
import { convertToTitleCase } from 'util/string';

// Styles
import './add-family-member-modals.style.scss';

export interface FamilyAccountConfirmModalProps {
    name: string;
    onCancelClick?: () => void;
    onContinueClick?: () => void;
    translation: TFunction<'translation'>;
}

export interface ErrorModalContentProps {
    memberName: string;
    errors: Array<string>;
    prescriptionNumber: string;
    onClose: () => void;
    t: TFunction<'translation'>;
}

export const FamilyAccountRemoveMemberModalContent = ({
    name,
    onCancelClick,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    const isLoading = useSelector(removeMemberIsLoadingSelector);

    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.familyAccount.removeMemberText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>
                    <ToastBox variant="descriptive">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    translation('modals.familyAccount.removeMemberToast', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })
                                )
                            }}
                        ></p>
                    </ToastBox>
                </Col>
            </Row>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.cancel')}
                        variant="link"
                        onClick={onCancelClick}
                        type="button"
                        disabled={isLoading}
                    />
                    <Button
                        async
                        label={translation('modals.familyAccount.removeMember')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                        isBusy={isLoading}
                        disabled={isLoading}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountRevokeAccessModalContent = ({
    name,
    onCancelClick,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.familyAccount.revokeAccessText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>
                </Col>
            </Row>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.cancel')}
                        variant="link"
                        onClick={onCancelClick}
                        type="button"
                    />
                    <Button
                        label={translation('modals.familyAccount.revokeAccess')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountDeclineAccessModalContent = ({
    name,
    onCancelClick,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.familyAccount.declineAccessText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>
                </Col>
            </Row>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.cancel')}
                        variant="link"
                        onClick={onCancelClick}
                        type="button"
                    />
                    <Button
                        label={translation('modals.familyAccount.declineAccess')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountInvitationExpiredModalContent = ({
    name,
    onCancelClick,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.familyAccount.invitationExpiredText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>

                    <ToastBox variant="descriptive">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(translation('modals.familyAccount.invitationExpiredToast'), {
                                    allowedTags: ['strong']
                                })
                            }}
                        ></p>
                    </ToastBox>
                </Col>
            </Row>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.cancelInvitation')}
                        variant="link"
                        onClick={onCancelClick}
                        type="button"
                    />
                    <Button
                        label={translation('modals.familyAccount.resendInvitation')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountSuccessModalContent = ({
    name,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                translation('modals.familyAccount.successText', { name: convertToTitleCase(name) }),
                                {
                                    allowedTags: ['strong', 'br', 'p']
                                }
                            )
                        }}
                    />
                </Col>
            </Row>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.gotIt')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                        className="with-min-width"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountInvitationPendingModalContent = ({
    name,
    onCancelClick,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.familyAccount.invitationPendingText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>

                    <ToastBox>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(translation('modals.familyAccount.invitationPendingToast'), {
                                    allowedTags: ['strong']
                                })
                            }}
                        ></p>
                    </ToastBox>
                </Col>
            </Row>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.cancelInvitation')}
                        variant="link"
                        onClick={onCancelClick}
                        type="button"
                    />
                    <Button
                        label={translation('modals.familyAccount.resendInvitation')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountAddMemberSuccessModalContent = ({
    name,
    onCancelClick,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.familyAccount.addMemberSuccessText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>
                </Col>
            </Row>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.gotIt')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountAddMemberInvitationSentModalContent = ({
    name,
    onCancelClick,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                translation('modals.familyAccount.addMemberInvitationSentText', {
                                    name: convertToTitleCase(name)
                                }),
                                {
                                    allowedTags: ['strong', 'br', 'p']
                                }
                            )
                        }}
                    />
                </Col>
            </Row>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.gotIt')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                        className="with-min-width"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountAddMemberGenericErrorModalContent = ({
    name,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.familyAccount.addMemberGenericErrorText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>
                </Col>
            </Row>
            <div className="foot-notes">
                <ToastBox variant="descriptive">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                translation('modals.easyRefillFailure.footNotes', {
                                    phoneNumber: getPhoneNumber({ isEnd: true })
                                })
                            )
                        }}
                    />
                </ToastBox>
            </div>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.gotIt')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountAddMemberInvitationErrorModalContent = ({
    name,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.familyAccount.addMemberInvitationFailedText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>
                </Col>
            </Row>
            <div className="foot-notes">
                <ToastBox variant="descriptive">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                translation('modals.easyRefillFailure.footNotes', {
                                    phoneNumber: getPhoneNumber({ isEnd: true })
                                })
                            )
                        }}
                    />
                </ToastBox>
            </div>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.gotIt')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountAddMemberAlreadyMemberErrorModalContent = ({
    name,
    onContinueClick,
    translation
}: FamilyAccountConfirmModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.membership.addMemberAlreadyMemberErrorText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>
                </Col>
            </Row>
            <div className="foot-notes">
                <ToastBox variant="descriptive">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                translation('modals.easyRefillFailure.footNotes', {
                                    phoneNumber: getPhoneNumber({ isEnd: true })
                                })
                            )
                        }}
                    />
                </ToastBox>
            </div>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.gotIt')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export const FamilyAccountErrorModal = ({
    memberName,
    errors,
    prescriptionNumber,
    onClose,
    t
}: ErrorModalContentProps) => {
    const errorMessage = mapFamilyAccountErrors(errors, prescriptionNumber);

    return (
        <div className="membership-error-modal">
            <div className="membership-error-modal--content">
                <div className="membership-error-modal--body">
                    {errorMessage !== undefined && (
                        <h4
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(t(errorMessage, { memberName }), {
                                    allowedTags: ['br']
                                })
                            }}
                        />
                    )}
                </div>

                <div className="membership-error-modal--foot-notes">
                    <ToastBox variant="descriptive">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('components.addFamilyMember.errorModals.footNote') +
                                        getPhoneNumber({ isEnd: true })
                                )
                            }}
                        />
                    </ToastBox>
                </div>
                <Button
                    label={t('components.membershipModals.buttons.gotIt')}
                    type="button"
                    variant="primary"
                    onClick={onClose}
                />
            </div>
        </div>
    );
};
